export function handleValidation(e, error, trigger, setIsValid) {
  const { name } = e.target;

  trigger(name);
  setIsValid((prev) => ({ ...prev, [name]: !error?.message }));

  if (error?.message === undefined || error?.message === null) {
    trigger(name);
    setIsValid((prev) => ({ ...prev, [name]: true }));
  }
}
