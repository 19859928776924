import getUserSessionId from '@helpers/getUserSessionId';
import axios from 'axios';
import { getAccessToken } from '../helpers/getAccessToken';
import { getApplicationToken } from '../helpers/getApplicationToken';

const BASE_URL = process.env.NEXT_PUBLIC_API;

function getGatewayURL(endpoint) {
  return `${BASE_URL || 'http://gateway:8080/gateway/v1'}${endpoint}`;
}

export async function serverSideApi(
  req,
  res,
  endpoint,
  method = 'GET',
  params = null,
  body = null,
  headers = {},
) {
  let options = {
    method,
    url: getGatewayURL(endpoint),
    params,
    data: body,
    headers: {
      'Content-Type': 'application/json',
      'X-UserSession': `${getUserSessionId(req, res) ? getUserSessionId(req, res) : ''}`,
      ...headers,
    },
  };
  try {
    options['headers']['X-ApplicationToken'] = await getApplicationToken(req, res);
    options['headers']['Authorization'] = `Bearer ${await getAccessToken(req, res)}`;
    return await axios(options);
  } catch (error) {
    if (
      error.response.status == 401 &&
      error?.response?.data?.detail == 'profile status changed.'
    ) {
      options['headers']['X-ApplicationToken'] = await getApplicationToken(req, res);
      options['headers']['Authorization'] = `Bearer ${await getAccessToken(req, res, true)}`;
      return await axios(options);
    } else if (
      error.response.status == 401 &&
      (error?.response?.data?.detail == 'Not authenticated' ||
        error?.response?.data?.detail == 'Unauthorized')
    ) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    } else {
      throw new Error(error.message);
    }
  }
}
