import axios from 'axios';
import Cookies from 'cookies';
import jwt_decode from 'jwt-decode';
import { getApplicationToken } from './getApplicationToken';

export async function getAccessToken(req, res, new_access_token) {
  const API_URL = process.env.NEXT_PUBLIC_API;
  const cookies = new Cookies(req, res);
  const refreshToken = cookies.get('refresh_token');
  const applicationToken = await getApplicationToken(req, res);
  let accessToken = cookies.get('access_token');

  if (new_access_token) {
    return await getUpdatedAccessToken();
  }

  if (refreshToken && accessToken) {
    const decodedJwt = jwt_decode(accessToken);
    const tokenExpiryDate = new Date(decodedJwt.exp * 1000);

    if (tokenExpiryDate <= new Date()) {
      try {
        const response = await axios.post(`${API_URL}/profile/refresh_token`, null, {
          headers: { bearer: refreshToken, 'X-ApplicationToken': applicationToken },
        });

        if (response && response.status === 200) {
          const newAccessToken = response.data.access_token;
          accessToken = newAccessToken;
          cookies.set('access_token', newAccessToken, {
            httpOnly: true,
            sameSite: 'strict',
          });
        } else {
          cookies.set('access_token');
          cookies.set('refresh_token');
        }
      } catch (error) {
        return null;
      }
    }

    return accessToken;
  } else if (refreshToken) {
    return await getUpdatedAccessToken();
  }

  async function getUpdatedAccessToken() {
    try {
      const response = await axios.post(`${API_URL}/profile/refresh_token`, null, {
        headers: { bearer: refreshToken },
      });

      if (response && response.status === 200) {
        const newAccessToken = response.data.access_token;
        accessToken = newAccessToken;
        cookies.set('access_token', newAccessToken, {
          httpOnly: true,
          sameSite: 'strict',
        });
      } else {
        cookies.set('access_token');
        cookies.set('refresh_token');
      }
      return accessToken;
    } catch (error) {
      return { error: 'unauthorized' };
    }
  }
}
