import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export function validatePhoneNumber(phoneNumber, country) {
  try {
    if (phoneNumber != null) {
      const number = phoneUtil.parse(phoneNumber, country);
      return phoneUtil.isValidNumberForRegion(number, country);
    }
  } catch (error) {
    return false;
  }
}

export function formatPhoneNumber(phoneNumber, country) {
  try {
    if (phoneNumber != null) {
      const number = phoneUtil.parse(phoneNumber, country);
      return phoneUtil.format(number, PhoneNumberFormat.E164);
    }
  } catch (error) {
    return false;
  }
}

export function getRegionForPhoneNumber(phoneNumber) {
  try {
    if (phoneNumber != null) {
      const number = phoneUtil.parse(phoneNumber);
      return phoneUtil.getRegionCodeForNumber(number);
    }
  } catch (error) {
    return null;
  }
}
