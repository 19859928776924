import { classNames } from '@helpers/classNames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { linkStyle } from './nextLinkConstants';

const NextLink = ({
  linkType = '',
  href = '',
  onClick,
  target = '',
  overrideClass = '',
  children,
}) => {
  return (
    <Link
      onClick={onClick}
      href={href}
      target={target}
      className={classNames(linkStyle[linkType], overrideClass)}
    >
      {children}
    </Link>
  );
};

// PropTypes that help devs to know what props are available to render the component
NextLink.propTypes = {
  linkType: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  overrideClass: PropTypes.string,
};

export default NextLink;
