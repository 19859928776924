// Basestyle that is applicable to all types of buttons
const baseStyle =
  'flex max-w-fit rounded-md tracking-wide justify-center gap-4 px-4 py-2.5 leading-5 items-center font-secondary';

// iconStyle, normal flex-row for icon on the right, reverse for icon on the left
const iconStyle = {
  right: `flex-row`,
  left: `flex-row-reverse`,
};

// For buttons, a disabled style is available for clicky buttons
// TODO change color to Tailwind Primary, Secondary, Tertiary 'custom' colors in tailwind.config.js
const buttonStyle = {
  primary:
    'bg-cta-primary shadow hover:bg-cta-primary-light text-white/90 disabled:bg-[#575757] disabled:text-functional-disabled',
  secondary:
    'disabled:bg-cta-secondary-light shadow disabled:text-functional-disabled bg-cta-secondary hover:bg-cta-secondary-dark text-cta-tertiary-dark',
  tertiary:
    'border-solid border-2 border-10 shadow border-[#575757] text-cta-tertiary-dark hover:bg-cta-tertiary-dark bg-functional-contrast disabled:hover:bg-functional-contrast hover:border-cta-tertiary-dark hover:text-functional-contrast disabled:border-cta-tertiary-light disabled:text-functional-disabled',
  transparent: 'tracking-wide text-primary-dark border border-white/50 shadow',
  danger:
    'bg-functional-error shadow hover:bg-functional-error text-primary-dark disabled:bg-functional-error-light disabled:text-cta-tertiary-dark',
  notLink: 'bg-transparent text-accent underline -ml-4',
};

export { baseStyle, buttonStyle, iconStyle };
