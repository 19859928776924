import Icon from '@elements/Icon';
import { classNames } from '@helpers/classNames';
import { baseStyle, buttonStyle, iconStyle } from './buttonConstants';

const entityChecker = (a, b) => a !== '' && b !== '';

const Button = ({
  buttonType = 'primary',
  label = '',
  disabled = false,
  type = 'button',
  onClick = () => {},
  icon = '',
  iconPosition = 'right',
  iconSize,
  overrideClass = '',
  hoverText = '',
}) => {
  const hasIcon = entityChecker(icon, iconPosition);

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        baseStyle,
        buttonStyle[buttonType],
        !!hasIcon && iconStyle[iconPosition],
        overrideClass,
      )}
      title={hoverText}
    >
      {label}
      {!!hasIcon && <Icon size={iconSize} iconName={icon} />}
    </button>
  );
};

export default Button;
